/* Space Grotesk */
@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/SpaceGrotesk-VariableFont_wght.ttf");
}

/* Inter */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-VariableFont.ttf");
}

/* Roboto Mono */
@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/fonts/RobotoMono-VariableFont_wght.ttf");
}

/* Roboto */
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

/* Roboto Bold */
@font-face {
  font-family: "Roboto Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}

/* Roboto Medium */
@font-face {
  font-family: "Roboto Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}

/* Barlow Semi-Bold */
@font-face {
  font-family: "Barlow SemiBold";
  src: url("./assets/fonts/Barlow-SemiBold.ttf");
}

/* Barlow Medium */
@font-face {
  font-family: "Barlow Medium";
  src: url("./assets/fonts/Barlow-Medium.ttf");
}

/* Futura */
@font-face {
  font-family: "Futura";
  src: url("./assets/fonts/futura_medium.ttf");
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
